import React from 'react';

const SectionTitle = ({title,content,classes}) => {
    return (
        <div className={`section-title text-center mb-40 ${classes}`}>
            <h1 className="title">{title}</h1>
            <p className="sub-title">{content}</p>
        </div>
    );
};

export default SectionTitle;