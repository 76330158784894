import React from 'react';
import {Link} from "react-router-dom";

const PortfolioDetailsContent = ({title, slug, category, description, meta}) => {

    const formattedDescription = description.split('/n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));

    return (
        <div className="portfolio-details-content">
            {/* <Link to={`/portfolio/${slug}`} className="category">{category}</Link> */}
            <h1 className="title">{title}</h1>
            <div className="project-share" style={{marginBottom: "15px"}}>
                {meta.socials && (
                    <>
                        {meta.socials.youtube && (
                            <a href={meta.socials.youtube} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-youtube" />
                            </a>
                        )}
                        {meta.socials.linkedin && (
                            <a href={meta.socials.linkedin} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-linkedin" />
                            </a>
                        )}
                        {meta.socials.instagram && (
                            <a href={meta.socials.instagram} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-instagram" />
                            </a>
                        )}
                        {meta.socials.facebook && (
                            <a href={meta.socials.facebook} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-facebook" />
                            </a>
                        )}
                    </>
                )}
            </div>

            <p>{formattedDescription}</p>
            <ul className="project-info">
            </ul>


        </div>
    );
};

export default PortfolioDetailsContent;