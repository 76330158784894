import React, { Component } from 'react'

class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error, info){
        this.setState({ error: error, errorInfo: info })
        window.location.reload();
    }

    render() {
        if(this.state.errorInfo){
            return (
                <div className="error-boundary">

                </div>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;