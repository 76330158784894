import React from 'react';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';

const PageContainer = ({children, classes, fullWidth}) => {

    const isMobile = window.innerWidth < 992;

    return (
        <div className={` ${classes}`}>
                    <div className={`section-wrap section ${!fullWidth ? 'section-padding' : null}`}>
                        {children}
                    </div>
        </div>
    );
};

export default PageContainer;