import React from 'react';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import './SlickCarousel.scss'; // Import the CSS file

const SlickCarousel = ({ imageData, settings }) => {
  return (
    <Slick className='section' style={{width: "100%", maxWidth: "100vw", marginTop: "60px"}}{...settings}>
      {imageData.images.map((image, index) => (
        <div key={index} className="carousel-item">
          <img src={image.src} alt={image.alt} className="carousel-image" />
        </div>
      ))}
    </Slick>
  );
};

export default SlickCarousel;
