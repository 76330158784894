import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import './Footer.scss';

const FooterThree = ({position}) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const body = document.querySelector('body');
        setIsDarkMode(body.classList.contains('dark-version'));
        const toggleIconElements = document.getElementsByClassName("toggle-icon");

        const handleClick = () => {
            setIsDarkMode(prevMode => !prevMode); // Toggle the state
        };

        // Attach the click event listener to all elements with class "toggle-icon"
        for (let i = 0; i < toggleIconElements.length; i++) {
            toggleIconElements[i].addEventListener('click', handleClick);
        }

        // Clean up the event listener on component unmount
        return () => {
            for (let i = 0; i < toggleIconElements.length; i++) {
                toggleIconElements[i].removeEventListener('click', handleClick);
            }
        };
    }, []);
    return (
        <div className={`footer-section section position-${position} ${isDarkMode ? 'dark-footer' : 'light-footer'} pt-30 pb-30 `}>
            <Container fluid>
                <Row className={'align-items-center'}>
                    <Col className={'footer-copyright text-center'}>
                        <p>&copy; {new Date().getFullYear()} Atos ATL</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default FooterThree;
