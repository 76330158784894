import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Scrollbar from 'perfect-scrollbar-react';
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree"
import SideHeader from "../../components/SideHeader";

const Account = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
    });

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <div style={{display: 'flex', width: '100%',  height: isMobile ? 'auto' : 'calc(100vh - 186px)'}}>
            {/* <Scrollbar options={{suppressScrollX: true}} style={{overflow:"hidden"}} > */}
            <SideHeader mobile={true}/>
                        {/* <a 
                            href={'https://cart.mindbodyonline.com/sites/93760/client'} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="btn btn-dark"
                        >
                            LOGIN
                        </a> */}
                        <iframe
                            src="https://cart.mindbodyonline.com/sites/93760/client"
                            style={{
                                width: '100%',
                                height: 'calc(100vh - 90px)',
                                // height: isMobile ? 'auto' : 'calc(100vh - 92px)',
                                border: 'none',
                            }}
                            title="MindBody Online Login"
                        ></iframe>
            {/* </Scrollbar> */}
            </div>
        </div>
    );
};

export default Account;