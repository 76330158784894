import React from 'react';
import { Link } from "react-router-dom";

const ProgramDetailsContent = ({ title, slug, category, description, meta }) => {
    const renderDescription = (description) => {
        return description.map((item, index) => {
            if (item.type === 'paragraph') {
                return <p key={index}>{item.content}</p>;
            }
            if (item.type === 'bold') {
                return <p key={index}><b>{item.content}</b></p>;
            }
            return null;
        });
    };

    return (
        <div className="portfolio-details-content">
            <Link to={`/portfolio/${slug}`} className="category">{category}</Link>
            <h1 className="title">{title}</h1>
            {renderDescription(description)}
        </div>
    );
};

export default ProgramDetailsContent;
