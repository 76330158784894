import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import './FreeTrial.scss';

const FreeTrial = () => {
    const [isResident, setIsResident] = useState(null);
    const [ageGroup, setAgeGroup] = useState(null);
    const [programType, setProgramType] = useState(null);

    const iframeURLs = {
        "Basics": "https://brandedweb.mindbodyonline.com/iframe/schedule/215997",
        "Fundamentals": "https://brandedweb.mindbodyonline.com/iframe/schedule/215991",
        "Advanced": "https://brandedweb.mindbodyonline.com/iframe/schedule/215996",
        "NoGi": "https://brandedweb.mindbodyonline.com/iframe/schedule/215995",
        "Kids 3+": "https://brandedweb.mindbodyonline.com/iframe/schedule/215998",
        "Kids 5-7": "https://brandedweb.mindbodyonline.com/iframe/schedule/215993",
        "Kids 8-12": "https://brandedweb.mindbodyonline.com/iframe/schedule/215994",
        "Competition": "https://brandedweb.mindbodyonline.com/iframe/schedule/215992"
        // Add more mappings as needed
    };

    const handleResidentSelect = (residentStatus) => {
        setIsResident(residentStatus);
        setAgeGroup(null);
        setProgramType(null);
    };

    const handleAgeGroupSelect = (group) => {
        setAgeGroup(group);
        setProgramType(null);
    };

    const handleProgramTypeSelect = (type) => {
        setProgramType(type);
    };

    // Function to scroll to the bottom of the page
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    };

        // useEffect to trigger scroll when the relevant states are updated
        useEffect(() => {
            if (programType || ageGroup || isResident) {
                scrollToBottom();
            }
        }, [programType, ageGroup,isResident]);

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>

            <div className="free-trial-container">
                <div className="header">
                    <h1>Book Academy Trial Class</h1>
                    <p>Book a free trial class and experience our world-class instruction. Trial Classes are only available to Georgia Residents.</p>
                </div>
                
                {/* Georgia Resident Selection */}
                <div style={{marginBottom:"40px"}}>            
                    <h4>Are you a resident of Fulton, Cobb, Gwinnet, or Cherokee County?</h4>
                    <div className="button-group">
                        <button 
                            className={`btn ${isResident === true ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handleResidentSelect(true)}
                        >
                            Yes
                        </button>
                        <button 
                            className={`btn ${isResident === false ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handleResidentSelect(false)}
                        >
                            No
                        </button>
                    </div>
                </div>

                {/* Show Guest Pass Button if Not a Georgia Resident */}
                {isResident === false && (
                    <div className={"button-group"} style={{ marginBottom: "40px" }}>
                        <Link to="/guest-pass" className="btn btn-dark">
                            Buy Guest Pass
                        </Link>
                    </div>
                )}

                {/* Age Group Selection */}
                {isResident === true && (
                    <div style={{marginBottom:"40px"}}>
                        <h4>Select Age Group</h4>
                        <div className="button-group">
                            <button 
                                className={`btn ${ageGroup === 'Kid' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleAgeGroupSelect('Kid')}
                            >
                                Kid
                            </button>
                            <button 
                                className={`btn ${ageGroup === 'Adult' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleAgeGroupSelect('Adult')}
                            >
                                Adult
                            </button>
                        </div>
                    </div>
                )}

                {/* Program Type Selection for Kids */}
                {ageGroup === 'Kid' && (
                    <div style={{marginBottom:"40px"}}>
                        <h4>Select Program Type</h4>
                        <div className="button-group">
                            <button 
                                className={`btn ${programType === 'Kids 3+' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Kids 3+')}
                            >
                                3-5
                            </button>
                            <button 
                                className={`btn ${programType === 'Kids 5-7' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Kids 5-7')}
                            >
                                5-7
                            </button>
                            <button 
                                className={`btn ${programType === 'Kids 8-12' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Kids 8-12')}
                            >
                                8-12
                            </button>
                            <button 
                                className={`btn ${programType === 'Competition' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Competition')}
                            >
                                Competition
                            </button>
                        </div>
                    </div>
                )}

                {/* Program Type Selection for Adults */}
                {ageGroup === 'Adult' && (
                    <div style={{marginBottom:"40px"}}>
                        <h4>Select Program Type</h4>
                        <div className="button-group">
                            <button 
                                className={`btn ${programType === 'Basics' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Basics')}
                            >
                                Basics
                            </button>
                            <button 
                                className={`btn ${programType === 'Fundamentals' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Fundamentals')}
                            >
                                Fundamentals
                            </button>
                            <button 
                                className={`btn ${programType === 'Advanced' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('Advanced')}
                            >
                                Advanced
                            </button>
                            <button 
                                className={`btn ${programType === 'NoGi' ? 'btn-dark' : 'btn-light'}`} 
                                onClick={() => handleProgramTypeSelect('NoGi')}
                            >
                                NoGi
                            </button>
                        </div>
                    </div>
                )}

                {/* Mindbody Iframe */}
                {programType && (
                    <div style={{ marginBottom: "40px", maxWidth: "600px", position: "relative", left: "50%", transform: "translateX(-50%)" }}>
                        <h4>Class Schedule</h4>
                        <div className="mindbody-widget">
                            <iframe
                                src={iframeURLs[programType]}  // Dynamically set the iframe URL
                                width="100%"
                                height="800px"
                                frameBorder="0"
                                title="Mindbody Schedule"
                            ></iframe>
                        </div>
                    </div>
                )}
            </div>

            <Footer position={'static'}/>
        </div>
    );
};

export default FreeTrial;
