import React, { useState, useEffect, useRef } from 'react';
import './GenericInfoImageLeft.scss';
import { motion, useInView, useAnimation } from "framer-motion";
import PropTypes from 'prop-types';

const GenericInfoImageLeft = ({ title, text, imageUrl, buttonLabel, buttonUrl }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const textControls = useAnimation();
    const imageControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            textControls.start("visible");
            imageControls.start("visible");
        }
    }, [isInView, textControls, imageControls]);

    useEffect(() => {
        const body = document.querySelector('body');
        setIsDarkMode(body.classList.contains('dark-version'));
    }, []);

    return (
        <div ref={ref} className={`section quote-section-right ${isDarkMode ? 'dark' : 'light'}`}>
            <div className="content-wrapper">
                {/* <motion.div
                    className="image-content"
                    variants={{
                        hidden: { opacity: 0, x: -75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={imageControls}
                    transition={{ duration: 0.5, delay: 0.25 }}
                >
                    <img src={imageUrl} alt="Visual Representation" />
                </motion.div> */}
                <motion.div
                    className="text-content"
                    variants={{
                        hidden: { opacity: 0, x: 75 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={textControls}
                    transition={{ duration: 0.5, delay: 0.25 }}
                >
                    <h1>{title}</h1>
                    <p>{text}</p>
                    {buttonLabel && (
                        <div className="button-wrapper">
                            <a href={buttonUrl} className={`btn ${isDarkMode ? 'btn-dark' : 'btn-light'}`}>{buttonLabel}</a>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
};

GenericInfoImageLeft.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string,
    buttonUrl: PropTypes.string,
};

GenericInfoImageLeft.defaultProps = {
    buttonLabel: '',
    buttonUrl: '',
};

export default GenericInfoImageLeft;
