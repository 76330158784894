import React, {useState, useEffect} from 'react';

// File imported
import Contact from "../../container/contact";
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import PersonalPortfolioTeam from "../../container/team";
import PersonalPortfolioService from "../../container/service";
import BannerPersonalPortfolio from "../../components/HeroBanner/PersonalPortfolio";
import InstructorPortfolio from '../../container/portfolio/InstructorPortfolio';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';

const Instructors = () => {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <div style={{display: 'flex', width: '100%', maxHeight: isMobile ? 'auto' : 'calc(100vh - 186px)'}}>
            <Scrollbar options={{suppressScrollX: true}}>
            <InstructorPortfolio/>
            {/* <PersonalPortfolioService/>
            <PersonalPortfolioTeam/>
            <Contact/> */}
            </Scrollbar>
            </div>
            <Footer position={'static'}/>
        </div>
    );
};

export default Instructors;
