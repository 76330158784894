import React, { useEffect, useState } from 'react';
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";
import SideHeader from "../../components/SideHeader";
import { NavLink } from 'react-router-dom'; // Import NavLink
import './WeekCalendar.css';

const WeekCalendar = ({ date, onChange, isMobile }) => {
  const [selectedGroup, setSelectedGroup] = useState("MonWed");

  const instructorSlugs = {
    'Prof Bruno': '/instructors/bruno-frazatto',
    'Prof Alex': '/instructors/alex-enrique',
    'Coach Andre': '/instructors/andre-cunha',
    'Prof Phillip': '/instructors/phillip-thomas',
    'Prof Bobby': '/instructors/bobby-chau',
    'Prof An': '/instructors/an-vu',
    'Coach Jesse': '/instructors/jesse-hauch'
  };

  const classSchedule = {
    "MonWed": [
      { day: 'Mon/Wed', classes: [
        { time: '6:00 AM - 7:30 AM',    name: 'Gi Fundamentals',      instructor: 'Prof Bruno' },
        { time: '6:00 AM - 7:00 AM',    name: 'Gi Basics',            instructor: 'Prof Alex' },
        { time: '7:30 AM - 9:00 AM',    name: 'Comp Class',           instructor: 'Prof Bruno' },
        { time: '12:00 PM - 1:00 PM',   name: 'Gi All Levels',        instructor: 'Coach Andre' },
        { time: '4:15 PM - 5:00 PM',    name: 'Kids 3-4',             instructor: 'Coach Jesse' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids 5-7',             instructor: 'Prof Alex' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids 8-13',            instructor: 'Prof Bobby' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids Comp',            instructor: 'Prof Bruno' },
        { time: '6:15 PM - 7:15 PM',    name: 'Gi Basics',            instructor: 'Coach Andre' },
        { time: '6:30 PM - 7:30 PM',    name: 'Gi Advanced',          instructor: 'Prof Bruno' }
      ]}
    ],
    "TueThu": [
      { day: 'Tue/Thu', classes: [
        { time: '9:00 AM - 10:30 AM',   name: 'No Gi All Levels',     instructor: 'Prof Bruno' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids No-Gi Comp',      instructor: 'Prof Bruno' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids 8-13',            instructor: 'Coach Andre' },
        { time: '6:15 PM - 7:15 PM',    name: 'Gi Basics',            instructor: 'Prof An' },
        { time: '6:00 PM - 7:30 PM',    name: 'No Gi All Levels',     instructor: 'Prof Bruno' }
      ]}
    ],
    "Fri": [
      { day: 'Fri', classes: [
        { time: '6:00 AM - 7:00 AM',    name: 'Gi Basics',            instructor: 'Prof Alex' },
        { time: '6:00 AM - 7:30 AM',    name: 'Gi Fundamentals',      instructor: 'Prof Bruno' },
        { time: '7:30 AM - 9:00 AM',    name: 'Comp Class',           instructor: 'Prof Bruno' },
        { time: '12:00 PM - 1:00 PM',   name: 'Gi All Levels',        instructor: 'Coach Andre' },
        { time: '5:00 PM - 6:00 PM',    name: 'Kids No Gi Comp',      instructor: 'Prof Bruno' },
        { time: '6:15 PM - 7:15 PM',    name: 'Gi All Levels',        instructor: 'Coach Andre' },
      ]}
    ], 
    "Sat": [
      { day: 'Sat', classes: [
        { time: '8:00 AM - 9:00 AM',    name: 'Gi + No Gi Situational Sparing',      instructor: 'Prof Bruno' },
        { time: '9:00 AM - 10:00 AM',   name: 'Kids Comp',            instructor: 'Prof Bruno' },
        { time: '10:00 AM - 11:00 AM',  name: 'Kids 5-7',             instructor: 'Prof Alex' },
        { time: '10:00 AM - 11:00 AM',  name: 'Kids 8-13',            instructor: 'Coach Andre' },
      ]}
    ]
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div className="section schedule-container">
      <div className="week-header button-group">
        <button onClick={() => handleGroupClick("MonWed")} className={`btn px80 ${selectedGroup === "MonWed" ? 'btn-dark' : 'btn-light'}`}>Mon/Wed</button>
        <button onClick={() => handleGroupClick("TueThu")} className={`btn  px80 ${selectedGroup === "TueThu" ? 'btn-dark' : 'btn-light'}`}>Tue/Thu</button>
        <button onClick={() => handleGroupClick("Fri")} className={`btn  px80 ${selectedGroup === "Fri" ? 'btn-dark' : 'btn-light'}`}>Fri</button>
        <button onClick={() => handleGroupClick("Sat")} className={`btn  px80 ${selectedGroup === "Sat" ? 'btn-dark' : 'btn-light'}`}>Sat</button>
      </div>

      <div className="schedule-content">
        {selectedGroup && classSchedule[selectedGroup].map((schedule, index) => (
          <div key={index} className="schedule-day" style={{ marginBottom: "45px", paddingLeft: "15px", paddingRight: "15px"}}>
            <h3 style={{ marginBottom: "45px"}}>{schedule.day}</h3>
            {schedule.classes.map((cls, idx) => (
              <div key={idx} className="classItem">
                <span className="classTime"><strong>{cls.time}</strong> </span>
                <span className="className">{cls.name} </span>
                <NavLink to={instructorSlugs[cls.instructor]} className="classInstructor">{cls.instructor}</NavLink>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const SchedulePage = () => {
  const [date, setDate] = useState(new Date());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={'main-wrapper p-0'}>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>
      <WeekCalendar date={date} onChange={setDate} isMobile={isMobile} />
      <Footer position={'static'}/>
    </div>
  );
};

export default SchedulePage;
