import React from 'react';
import { Link } from 'react-router-dom';
import './PageSelect.scss';


const PageSelect = ({name, designation, profilePhoto, socials, page}) => {
    const route = `${process.env.PUBLIC_URL || ''}/${page}`

    return (
        <>
            <Link to={`${page}`} className="nav-gallery-inner">
                <img src={require('../../assets/images/' + profilePhoto)} alt={name} />
                <div className="nav-gallery-content"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            background: 'radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 80%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                    <h4 style={{color: "white !important", position: "absolute"}}>{name}</h4>
                </div>
            </Link>
        </>               
    );
};

export default PageSelect;