import React, { useState, useEffect } from 'react';
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";

const GuestPass = () => {
    const [passType, setPassType] = useState(null);

    const handlePassTypeSelect = (type) => {
        setPassType(type);
    };

    const getMindbodyUrl = () => {
        switch (passType) {
            case '1 Session':
                return "https://cart.mindbodyonline.com/sites/93760/cart/add_service?mbo_item_id=100007";
            case '1 Day':
                return "https://cart.mindbodyonline.com/sites/93760/cart/add_service?mbo_item_id=100009";
            case '1 Week':
                return "https://cart.mindbodyonline.com/sites/93760/cart/add_service?mbo_item_id=100008";
            case '1 Month':
                return "https://cart.mindbodyonline.com/sites/93760/cart/add_service?mbo_item_id=103207";
            default:
                return "#";
        }
    };

        // Function to scroll to the bottom of the page
        const scrollToBottom = () => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        };
    
            // useEffect to trigger scroll when the relevant states are updated
            useEffect(() => {
                if (passType) {
                    scrollToBottom();
                }
            }, [passType]);

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>

            <div className="free-trial-container">
                <div className="header">
                    <h1>Purchase Guest Pass</h1>
                    <p>Select the type of guest pass you want to purchase.</p>
                </div>
                
                {/* Pass Type Selection */}
                <div style={{marginBottom:"80px"}}>
                    <h4>Select Pass Type</h4>
                    <div className="button-group">
                        <button 
                            className={`btn px150 ${passType === '1 Session' ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handlePassTypeSelect('1 Session')}
                        >
                            1 Session
                        </button>
                        <button 
                            className={`btn px150 ${passType === '1 Day' ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handlePassTypeSelect('1 Day')}
                        >
                            1 Day
                        </button>
                        <button 
                            className={`btn px150 ${passType === '1 Week' ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handlePassTypeSelect('1 Week')}
                        >
                            1 Week
                        </button>
                        <button 
                            className={`btn px150 ${passType === '1 Month' ? 'btn-dark' : 'btn-light'}`} 
                            onClick={() => handlePassTypeSelect('1 Month')}
                        >
                            1 Month
                        </button>
                    </div>
                </div>

                {/* Show Buy Pass Button */}
                {passType && (
                    <div className="button-group" style={{ marginBottom: "40px" }}>
                        <a 
                            href={getMindbodyUrl()} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="btn btn-dark"
                        >
                            Buy {passType} Pass
                        </a>
                    </div>
                )}
            </div>

            <Footer position={'static'}/>
        </div>
    );
};

export default GuestPass;
