import React, { useState, useEffect } from 'react';
import toggleImage from '../../assets/images/logo-transparent.png'; // Make sure to replace this with the actual path to your image

const LogoToggle = () => {
    const openSideMenu = () => {
        const sideMenuWrap = document.querySelector(".side-header");
        const overlay = document.querySelector('.side-menu-overlay');
        sideMenuWrap.classList.add('side-menu-open');
        overlay.classList.add('overlay-show');
    };

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const body = document.querySelector('body');
        if (body.classList.contains('dark-version')) {
            setIsDarkMode(true);
        }
    }, []);

    const toggleTheme = () => {
        const body = document.querySelector('body');
        body.classList.toggle('dark-version');
        setIsDarkMode(!isDarkMode);
        invertLogoColors(!isDarkMode);
    };

    const invertLogoColors = (invert) => {
        const logo = document.getElementsByClassName('toggle-icon')[0];
        if (logo) {
            logo.style.filter = invert ? 'invert(1)' : 'invert(0)';
        }
    };

    return (
        <div className="logo-toggle d-flex align-items-center justify-content-end col-lg-2 col-6">
            <img 
                src={toggleImage} 
                alt="Toggle Icon" 
                className="toggle-icon" 
                onClick={toggleTheme} 
            />
            <button
                className="side-header-toggle d-block d-lg-none order-12 ml-20"
                onClick={openSideMenu}
            >
                <span/>
            </button>
        </div>
    );
};

export default LogoToggle;
