import React, {Fragment} from 'react';
import programs from "../../../data/program";
import SideHeader from "../../../components/SideHeader";
import useGetParams from "../../../hooks/useGetParams";
import Header from "../../../components/header/HeaderOne";
import FooterThree from "../../../components/footer/FooterThree";
import TemplateProgramDetails from "../../../templates/program-details";
import HeroButtons from "../../../components/generic/HeroButtons";
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';

const ProgramDetailsPage = () => {
    const {slug} = useGetParams();
    const program = programs?.find(program => program.slug === `/program/${slug}`);
    const isMobile = window.innerWidth < 992;


    return (
        <Fragment>
            <Header classes={'position-static'} />
            <div style={{ display: 'flex', width: '100%', maxHeight: isMobile ? 'auto' : 'calc(100vh - 186px)' }}>
                <Scrollbar options={{ suppressScrollX: true }} enable={!isMobile}>
                    <SideHeader mobile={true} />
                    <TemplateProgramDetails program={program} />
                    <HeroButtons />
                </Scrollbar>
            </div>
            <FooterThree position='fixed' />
        </Fragment>
    );
};

export default ProgramDetailsPage;