import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';

const InstructorPortfolioItem = ({ slug, thumb, classes, title, category, variant }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const controls = useAnimation();

    const itemVariants = {
        hidden: { opacity: 0, x: 75 },
        visible: { opacity: 1, x: 0 },
    };

    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        }
    }, [isInView, controls]);

    return (
        <motion.div
            ref={ref}
            className={`portfolio-item ${classes ? classes : ' '}`}
            variants={itemVariants}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5 }}
        >
            <Link
                className={'d-block'}
                to={`/instructors/${slug}`}
            >
                {variant === 'column' ? (
                    <div className="portfolio-image">
                        <img src={require('../../../assets/images/Instructors/' + thumb)} alt={title} />
                    </div>
                ) : (
                    <div
                        className="portfolio-image"
                        style={{ backgroundImage: `url(${require('../../../assets/images/Instructors/' + thumb)})` }}
                    />
                )}
                <div className="portfolio-content">
                    <h4 className="title">{title}</h4>
                    <div className="category"><span>{category}</span></div>
                </div>
            </Link>
        </motion.div>
    );
};

export default InstructorPortfolioItem;
